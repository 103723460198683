/* Thank You Page Styles */
:root {
  --primary-gradient: linear-gradient(to right, #059669, #10B981);
  --hover-gradient: linear-gradient(to right, #10B981, #34D399);
  --primary-green: #059669;
  --text-gray: #4B5563;
  --button-background-color: #059669;
  --primary-text-color: #1F2937;
  --secondary-text-color: #4B5563;
  --body-text-color: #6B7280;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --card-border-color: #E5E7EB;
}

.thank-you-page {
  min-height: 100vh;
  background: #F9FAFB;
}

/* Navigation */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  z-index: 50;
}

.navbar-content {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.logo-icon {
  height: 2rem;
  width: auto;
}

.logo-text {
  height: 1.5rem;
  width: auto;
}

/* Main Content */
#thank-you {
  padding: 8rem 0 4rem;
}

.thank-you-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.thank-you-content {
  background: white;
  padding: 3rem;
  border-radius: 1.25rem;
  box-shadow: 0 10px 48.34px var(--shadow-color);
  border: 1px solid var(--card-border-color);
  text-align: center;
}

.thank-you-image {
  margin-bottom: 2rem;
}

.thank-you-image svg {
  width: 100px;
  height: 100px;
}

.thank-you-content h1 {
  font-size: 2.75rem;
  color: var(--primary-text-color);
  margin: 0 0 2rem 0;
  font-weight: 700;
}

.message {
  margin-bottom: 2rem; /* Reduced from 2.5rem */
}

.message .primary {
  font-size: 1.5rem;
  color: var(--primary-text-color);
  margin: 0 0 0.625rem 0;
  font-weight: 500;
}

.message .secondary {
  font-size: 1.25rem;
  color: var(--secondary-text-color);
  margin: 0;
}

.next-steps {
  margin-top: 0; /* Remove top margin since it comes after buttons now */
  margin-bottom: 2rem;
}

.next-steps h3 {
  font-size: 1.5rem;
  color: var(--primary-text-color);
  margin: 0 0 1.25rem 0;
  font-weight: 500;
}

.next-steps ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.next-steps li {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  font-size: 1rem;
  color: var(--body-text-color);
  margin-bottom: 1rem;
}

.next-steps li:last-child {
  margin-bottom: 0;
}

.check-icon {
  color: var(--primary-green);
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.fun-fact {
  margin: 2rem 0;
  padding: 1.25rem;
  border-left: 4px solid var(--button-background-color);
  background-color: rgba(77, 175, 79, 0.1);
  text-align: left;
}

.fun-fact p {
  color: var(--body-text-color);
  font-size: 1rem;
  margin: 0;
  line-height: 1.6;
}

/* Button Styles */
.cta-buttons {
  display: flex;
  gap: 1.25rem;
  justify-content: center;
  margin-bottom: 2.5rem; /* Added margin-bottom */
}


/* Original button style (for Return to Home) */
.button {
  height: 3.5rem;
  padding: 0 2rem;
  border-radius: 1.75rem;
  font-size: 1.125rem;
  font-weight: 500;
  text-decoration: none;
  transition: opacity 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button.secondary {
  background-color: transparent;
  color: var(--primary-text-color);
  border: 2px solid var(--button-background-color);
}

.button.secondary:hover {
  opacity: 0.9;
}

/* Hero style button (for Follow Us on LinkedIn) */
.primary-button {
  position: relative;
  height: 3.5rem;
  padding: 0 2rem;
  background: var(--primary-gradient);
  color: white;
  border: none;
  border-radius: 1.75rem;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.primary-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--hover-gradient);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.primary-button:hover::before {
  opacity: 1;
}

.button-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.button-content svg {
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease;
}

.primary-button:hover .button-content svg {
  transform: translateX(2px);
}

/* Footer Styles */
.footer {
  padding: 4rem 0 2rem;
}

.footer-container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.footer-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.footer-logo a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.footer-social img {
  width: 24px;
  height: 24px;
}

.footer-separator {
  height: 1px;
  background-color: #E5E7EB;
  margin: 2rem 0;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  color: var(--body-text-color);
  font-size: 0.875rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-links a {
  color: var(--body-text-color);
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.2s ease;
}

.footer-links a:hover {
  color: var(--primary-green);
}
