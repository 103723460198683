/* Contact Page Styles */
.contact-page {
  min-height: 100vh;
  background: #F9FAFB;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  z-index: 50;
}

.navbar-content {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 2.5rem;
}

.nav-links a {
  color: #4B5563;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.125rem;
}

.contact-main {
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.contact-container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

.contact-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.header-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #111827;
  margin-bottom: 1rem;
}

.header-content p {
  font-size: 1.125rem;
  color: #4B5563;
  line-height: 1.6;
}

.expectations-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.expectations-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1.5rem;
}

.expectations-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.expectations-list li {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.check-icon {
  color: #059669;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.contact-right {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.form-group input,
.form-group select {
  padding: 0.75rem 1rem;
  border: 1px solid #D1D5DB;
  border-radius: 9999px;
  font-size: 1rem;
  color: #111827;
}

.form-group textarea {
  padding: 0.75rem 1rem;
  border: 1px solid #D1D5DB;
  border-radius: 1rem;
  font-size: 1rem;
  color: #111827;
  resize: vertical;
  min-height: 100px;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #059669;
  box-shadow: 0 0 0 2px rgba(5, 150, 105, 0.1);
}

.submit-button {
  background: linear-gradient(to right, #059669, #10B981);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 9999px;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.submit-button:hover {
  opacity: 0.9;
}

.form-disclaimer {
  text-align: center;
  font-size: 0.875rem;
  color: #6B7280;
}

.form-disclaimer a {
  color: #059669;
  text-decoration: none;
}

.form-disclaimer a:hover {
  text-decoration: underline;
}

/* Error states */
.form-group input.error,
.form-group select.error,
.form-group textarea.error {
  border-color: #EF4444;
}

.error-message {
  color: #EF4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.submit-error {
  background-color: #FEE2E2;
  border: 1px solid #EF4444;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-bottom: 1rem;
  text-align: center;
}

/* Loading state */
.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}


/* Tablet and Mobile Styles */
@media (max-width: 1024px) {
  .contact-grid {
    grid-template-columns: 1fr; /* Stack columns */
    gap: 2rem; /* Reduce gap */
  }

  .contact-left,
  .contact-right {
    width: 100%;
  }

  /* Adjust header text sizes */
  .header-content h1 {
    font-size: 2rem;
    text-align: center;
  }

  .header-content p {
    font-size: 1rem;
    text-align: center;
  }

  /* Adjust spacing */
  .contact-main {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  .expectations-card {
    padding: 1.5rem;
  }

  .contact-right {
    padding: 1.5rem;
  }
}

/* Mobile Styles */
@media (max-width: 640px) {
  .contact-grid {
    gap: 1.5rem;
  }

  .header-content h1 {
    font-size: 1.75rem;
  }

  .expectations-card {
    padding: 1rem;
  }

  .contact-right {
    padding: 1rem;
  }

  .form-group input,
  .form-group select,
  .submit-button {
    height: 45px; /* Make inputs more touch-friendly */
  }

  /* Adjust form spacing */
  .contact-form {
    gap: 1rem;
  }

  .form-disclaimer {
    font-size: 0.75rem;
  }

  /* Make submit button full width on mobile */
  .submit-button {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
  }
}
