/* General Styles */
:root {
  --primary-gradient: linear-gradient(to right, #059669, #10B981);
  --hover-gradient: linear-gradient(to right, #10B981, #34D399);
  --text-gradient: linear-gradient(to right, #111827, #4B5563);
  --background-gradient: linear-gradient(180deg, #F9FAFB 0%, #FFFFFF 100%);
  --card-gradient: linear-gradient(to bottom right, #ECFDF5, #EFF6FF);
  --max-width: 80rem;
  --primary-green: #059669;
  --text-gray: #4B5563;
}



/* Reset & Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  color: var(--text-gray);
  background: var(--background-gradient);
}

body::before {
  content: '';
  position: fixed;
  inset: 0;
  background: var(--background-gradient);
  z-index: -1;
}

/* General Layout */
.landing-container {
  min-height: 100vh;
  background: var(--background-gradient);
  /*overflow-x: hidden;*/
}

/* ------------------------------------------------------------------------------
   Navigation Styles
-------------------------------------------------------------------------------- */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  z-index: 50;
}

.navbar-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 1.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center; /* Vertically centers items */
  gap: 0.75rem;
  height: 2rem; /* Set a fixed height */
}

.logo-icon {
  height: 2rem; /* Match parent height */
  width: auto; /* Maintain aspect ratio */
  display: flex;
  align-items: center;
}

.logo-text {
  height: 1.5rem; /* Slightly smaller than the icon */
  width: auto;
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 2.5rem;
}

.nav-links a {
  color: var(--text-gray);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.nav-links a:hover {
  color: #111827;
}

/* Desktop Navigation */
.desktop-menu {
  display: flex;
  gap: 2.5rem;
}

.nav-links.desktop-menu {
  display: flex;
  gap: 2.5rem;
}

/* Mobile Menu Toggle Button */
.mobile-menu-button {
  display: none; /* Hidden by default */
  background: none;
  border: none;
  color: var(--text-gray);
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s ease;
}

.mobile-menu-button:hover {
  color: #111827;
}

/* Mobile Navigation Menu */
.mobile-menu {
  position: fixed;
  top: 5rem; /* Height of navbar */
  left: 0;
  right: 0;
  background: white;
  padding: 1rem 0;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 40;
}

.mobile-menu.open {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the links */
  padding: 1rem 0;
}

.mobile-menu-content a {
  color: var(--text-gray);
  text-decoration: none;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  width: 100%; /* Full width for touch target */
  text-align: center; /* Center the text */
  transition: background-color 0.2s ease, color 0.2s ease;
}

.mobile-menu-content a:hover {
  background-color: #F3F4F6;
  color: #111827;
}

/* ------------------------------------------------------------------------------
   Hero Section Styles
-------------------------------------------------------------------------------- */
.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 6rem 0 4rem;
}

.hero-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: left;
  max-width: 36rem;
}

.hero-title {
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: -0.025em;
  background: var(--text-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: left;
  padding-bottom: 5px;
}

.hero-description {
  font-size: 1.25rem;
  color: var(--text-gray);
  margin: 0;
  text-align: left;
}

.hero-cta {
  margin-top: 1rem;
  text-align: left;
}

.hero-image {
  position: relative;
  width: 100%;
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%; /* 4:3 Aspect Ratio */
}

.image-background-effect {
  position: absolute;
  top: -2rem;
  left: -2rem;
  right: -2rem;
  bottom: -2rem;
  background: linear-gradient(135deg, #D1FAE5 0%, #DBEAFE 100%);
  border-radius: 2.5rem;
  transform: rotate(6deg);
  transform-origin: center;
  z-index: 1;
}

.hero-illustration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2rem;
  z-index: 2;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

/* Button Design */
.primary-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 1rem 2rem;
  background: var(--primary-gradient);
  color: white;
  border: none;
  border-radius: 9999px;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  overflow: hidden;
  transition: background 0.2s ease;
}

.primary-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--hover-gradient);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.primary-button:hover::before {
  opacity: 1;
}

.button-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.button-content svg {
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease;
}

.primary-button:hover .button-content svg {
  transform: translateX(2px);
}

/* Ensure proper color contrast */
.primary-button,
.primary-button:hover {
  color: white;
}

/* ------------------------------------------------------------------------------
   Features Section Styles
-------------------------------------------------------------------------------- */
#features {
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
  overflow: hidden;
  padding-top: 75px; /* Added 75px top padding */
}

.features-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Add this */
}

.features-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: var(--text-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  max-width: 48rem;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  text-align: center; /* Add this */
}

.features-section-description {
  font-size: 1.25rem;
  color: var(--text-gray);
  max-width: 36rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  text-align: center; /* Add this */
}

.feature-card {
  position: relative;
  height: 380px;
  transition: transform 0.3s ease;
}

.feature-card-background {
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, #D1FAE5 0%, #DBEAFE 100%);
  border-radius: 1rem;
  opacity: 0;
  transform: scale(0.95);
  transition: all 0.3s ease;
}

.feature-card:hover .feature-card-background {
  opacity: 1;
  transform: scale(1.05);
}


.feature-card-content {
  position: relative;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-gray);
  text-align: center;
}

.feature-description {
  color: var(--text-gray);
  flex-grow: 1;
  line-height: 1.6;
  font-size: 1rem;
}

/* Features Grid Layout */
.features-grid-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  margin-bottom: 50px !important;
  max-width: calc(var(--max-width) - 4rem);
}

.features-grid {
  display: grid;
  gap: 2rem;
  width: 100%;
}

.features-grid-top {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 0 auto;
}

.features-grid-bottom {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  margin: 0 auto;
}

.feature-card {
  position: relative;
  height: 380px;
  transition: transform 0.3s ease;
  width: 100%;
}
/* ------------------------------------------------------------------------------
   Benefits Section Styles
-------------------------------------------------------------------------------- */
.benefits-section {
  padding: 6rem 0;
  min-height: 100vh;
}


.benefits-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 2rem;
}

.benefits-content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  min-height: 100vh;
}

/* Left Side - Image */
.benefits-image-wrapper {
  position: sticky;
  top: calc(6rem + 45px); /* Adds 10px space after navbar */
  height: 600px;
  width: 90%;
  margin: 0;
}

.benefits-illustration-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.image-background-effect {
  position: absolute;
  top: -1.8rem;
  left: -1.8rem;
  right: -1.8rem;
  bottom: -1.8rem;
  background: linear-gradient(135deg, #D1FAE5 0%, #DBEAFE 100%);
  border-radius: 2.5rem;
  transform: rotate(-6deg);
  z-index: 1;
}

.benefits-illustration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2rem;
  z-index: 2;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

/* Right Side - Content */
.benefits-text-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.benefits-title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #1F2937;
  text-align: left;
  margin-bottom: 1rem;
}

.benefits-description {
  font-size: 1.25rem;
  color: #6B7280;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 2rem;
}

/* Benefits Grid */
.benefits-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Benefit Card Styles */
.benefit-card {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 1rem;
  align-items: start;
  padding: 1rem;
  background: white;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.benefit-card:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.benefit-icon-wrapper {
  width: 48px;
  height: 48px;
  background: #ECFDF5;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  align-self: center;
}

.benefit-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.benefit-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.benefit-card-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1F2937;
  text-align: left;
}

.benefit-card-description {
  font-size: 0.875rem;
  color: #6B7280;
  line-height: 1.5;
  text-align: left;
}

/* ------------------------------------------------------------------------------
   Pricing Section Styles
-------------------------------------------------------------------------------- */
.pricing-section {
  padding: 6rem 0;
}

.pricing-container {
  max-width: calc(var(--max-width) + 300px);
  margin: 0 auto;
  padding: 0 2rem;
}

.pricing-header {
  text-align: center;
  margin-bottom: 2rem;
}

.pricing-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: var(--text-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 1rem;
}

.pricing-description {
  font-size: 1.25rem;
  color: var(--text-gray);
  max-width: 36rem;
  margin: 0 auto;
}

/* Pricing Cards Grid */
.pricing-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  justify-content: center;
  margin: 0 auto;
}

/* Individual Card */
.pricing-card {
  background: white;
  border-radius: 1rem;
  border: 1px solid #E5F4F2;
  box-shadow: 0 10px 48.34px rgba(171, 171, 171, 0.1);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
}

/* Card Title */
.pricing-card h3 {
  font-size: 1.375rem;
  font-weight: 600;
  color: var(--text-gray);
  text-align: left;
  margin-bottom: 1rem;
}

/* Card Description */
.pricing-card > p {
  font-size: 0.875rem;
  color: var(--text-gray);
  text-align: left;
  margin-bottom: 2rem;
  line-height: 1.5;
}

/* Price Container */
.price-container {
  display: flex;
  align-items: baseline;
  margin-bottom: 2rem;
}

.price {
  font-size: 2.25rem;
  font-weight: 700;
  color: var(--primary-green);
}

.period {
  font-size: 0.875rem;
  color: var(--text-gray);
  margin-left: 0.5rem;
}

/* Features Section */
.pricing-card h5 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-gray);
  text-align: left;
  margin-bottom: 1.5rem;
}

/* Features List */
.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.pricing-card ul li {
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  color: var(--text-gray);
  margin-bottom: 1rem;
  text-align: left;
}

.pricing-card ul li img {
  width: 20px;
  height: 20px;
  margin-right: 0.75rem;
  flex-shrink: 0;
}

/* Button */
.pricing-card .primary-button {
  width: 100%;
  height: 52px;
  margin-top: auto;
  border-radius: 26px;
  background: var(--primary-gradient);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.pricing-card .primary-button span {
  position: relative;
  z-index: 3;
  color: white;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 21px;
  white-space: nowrap;
}

.pricing-card .primary-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--hover-gradient);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.pricing-card .primary-button:hover::before {
  opacity: 1;
}

/* ------------------------------------------------------------------------------
   CTA Section Styles
-------------------------------------------------------------------------------- */
/* CTA Section */
.cta-section {
  padding: 2rem 0;
}

.cta-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* Left Side */
.cta-left {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta-left h2 {
  font-size: 2.75rem;
  font-weight: 700;
  color: #1F2937;
  line-height: 1.2;
  margin: 0;
  text-align: left;
}

/* Right Side */
.cta-right {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 100px;
}

.cta-description {
  font-size: 1.25rem;
  color: #6B7280;
  line-height: 1.5;
  margin: 0 0 1.5rem 0;
  text-align: left;
  width: 100%;
}

.cta-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cta-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cta-form input[type="email"] {
  width: 100%;
  height: 56px;
  padding: 0 1.5rem;
  border: 1px solid #9CA3AF;
  border-radius: 32px;
  font-size: 1rem;
  color: var(--text-gray);
  outline: none;
  margin-bottom: 1rem;
}

.cta-form input[type="email"]::placeholder {
  color: #9CA3AF;
}

.cta-form button {
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 32px;
  background: var(--primary-gradient);
  color: white;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  position: relative;
  margin-bottom: 10px;
}

.cta-form button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--hover-gradient);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.cta-form button:hover::before {
  opacity: 1;
}

.cta-form button span {
  position: relative;
  z-index: 1;
}

.cta-disclaimer {
  font-size: 0.875rem;
  color: #6B7280;
  margin: 0;
  text-align: center;
  width: 100%;
}
/* ------------------------------------------------------------------------------
   Footer Styles
-------------------------------------------------------------------------------- */
.footer {
  padding: 4rem 0 2rem;
}

.footer-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 2rem;
}

/* Main Footer */
.footer-main {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centers items vertically */
  margin-bottom: 2rem;
}

/* Logo */
.footer-logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  height: 2rem; /* Set consistent height */
}

.footer-logo .logo-icon {
  height: 2rem;
}

.footer-logo .logo-text {
  height: 1.5rem;
}

/* Social Icons */
.footer-social {
  display: flex;
  align-items: center; /* Centers social icons vertically */
  gap: 1rem;
}

.footer-social img {
  width: 24px;
  height: 24px;
  display: block; /* Removes any unwanted space */
}

/* Separator */
.footer-separator {
  height: 2px;
  background-color: #E5E7EB;
  margin: 2rem 0;
}

/* Footer Bottom */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: var(--text-gray);
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-links a {
  color: var(--text-gray);
  text-decoration: none;
  transition: color 0.2s ease;
}

.footer-links a:hover {
  color: var(--primary-green);
}

/* Copyright */
.copyright {
  color: var(--text-gray);
}

/* ------------------------------------------------------------------------------
   Responsive Design
-------------------------------------------------------------------------------- */

/* Extra Large Screens (1280px and up) */
/* No specific changes needed at this breakpoint */

/* Large Screens (1024px to 1279px) */
@media (max-width: 1279px) {
  /* Navigation Adjustments */
  .navbar-content {
    padding: 0 1.5rem;
  }

  /* Hero Section Adjustments */
  .hero-content {
    grid-template-columns: 1fr;
    gap: 2rem;
    display: flex;
    flex-direction: column;
  }

  .hero-text {
    text-align: center;
    align-items: center;
    order: 2; /* This ensures text comes after the image */
  }

  .hero-title {
    font-size: 3rem;
  }

  .hero-description {
    font-size: 1.125rem;
  }

  .hero-cta {
    text-align: center;
  }

  .hero-image {
    order: -1; /* This moves the image above the text */
    margin-bottom: 2rem;
    padding: 1.5rem;
  }

  /* Features Section Adjustments */
  .features-grid-top,
  .features-grid-bottom {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .features-grid-wrapper {
    gap: 2rem;
  }

  /* Benefits Section Adjustments */
  .benefits-content {
    grid-template-columns: 1fr;
    gap: 4rem;
  }

  .benefits-image-wrapper {
    position: relative;
    top: auto;
    max-width: 500px;
    height: 400px; /* Reduced from 600px */
    margin: 0 auto;
  }

  .benefits-text-content {
    text-align: center;
    align-items: center;
  }

  .benefits-title,
  .benefits-description {
    text-align: center;
  }

  /* Pricing Section Adjustments */
  .pricing-cards {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }

  .pricing-card {
    max-width: 500px;
    margin: 0 auto;
  }

  /* CTA Section Adjustments */
  .cta-container {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .cta-left {
    flex-basis: 100%;
    width: 100%;
    text-align: center;
  }

  .cta-left h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .cta-right {
    flex-basis: 100%;
    width: 100%;
    margin-left: 0;
    align-items: center;
  }

  .cta-description,
  .cta-disclaimer {
    text-align: center;
  }

  .cta-description {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 1.5rem;
  }

  /* Adjust form layout */
  .cta-form {
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    position: relative; /* Ensure positioning context for absolute elements */
  }

  .cta-form input[type="email"] {
    width: 100%;
    padding: 0 1.5rem;
    height: 56px; /* Increased height */
    margin-bottom: 1rem;
    border-radius: 28px;
    box-sizing: border-box;
  }

  .cta-form button {
    position: relative;
    width: 100%;
    padding: 0 2rem;
    height: 56px; /* Match input height */
    border-radius: 28px;
    z-index: 1; /* Ensure button is above hover effect */
    overflow: hidden;
  }

  /* Fix hover effect overlap */
  .cta-form button::before {
    z-index: -1; /* Place hover effect behind the button */
  }
}

/* Tablets & Small Laptops (768px to 1023px) */
@media (max-width: 1023px) {
  /* Hero Section Adjustments */
  .hero-title {
    font-size: 2.75rem;
  }

  .hero-description {
    font-size: 1.125rem;
  }

  /* Features Section Adjustments */
  .features-title {
    font-size: 2rem;
  }

  /* CTA Section Adjustments */
  .cta-left h2 {
    font-size: 2.5rem;
  }

  .cta-description {
    font-size: 1.125rem;
  }

  .cta-form input[type="email"],
  .cta-form button {
    height: 52px; /* Adjusted height */
    border-radius: 26px;
  }
}

/* Screens smaller than 768px */
@media (max-width: 767px) {
  /* Navigation Adjustments */
  .navbar-content {
    padding: 0 1rem;
  }

  .nav-links {
      display: none; /* This will hide the desktop links */
    }

  .nav-links.desktop-menu {
    display: none !important; /* Force hide desktop menu */
    }

  .mobile-menu-button {
    display: block;
    }

  .desktop-menu {
    display: none;
  }

  .logo-text {
    height: 1rem; /* Smaller height for mobile */
  }

  .logo {
    gap: 0.5rem; /* Reduce gap between icon and text */
  }

  /* Hero Section Adjustments */
  .hero {
    padding: 6rem 0 3rem;
  }

  .hero-title {
    font-size: 2.5rem;
    text-align: center;
  }

  .hero-description {
    font-size: 1rem;
    text-align: center;
  }

  .hero-image {
    padding: 1rem;
  }

  .image-background-effect {
    top: -1rem;
    left: -1rem;
    right: -1rem;
    bottom: -1rem;
  }

  /* Features Section Adjustments */
  .features-title {
    font-size: 2rem;
    text-align: center;
  }

  .features-section-description {
    font-size: 1rem;
    text-align: center;
  }

  .feature-card {
    height: auto;
    min-height: 280px;
  }

  .feature-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 1rem;
  }

  .feature-title {
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }

  /* Benefits Section Adjustments */
  .benefits-section {
    padding: 4rem 0;
  }

  .benefits-title {
    font-size: 1.875rem;
    text-align: center;
  }

  .benefits-description {
    font-size: 1rem;
    text-align: center;
  }

  .benefit-card {
    padding: 0.875rem;
    gap: 0.875rem;
  }

  .benefit-icon-wrapper {
    width: 40px;
    height: 40px;
  }

  /* Pricing Section Adjustments */
  .pricing-section {
    padding: 4rem 0;
  }

  .pricing-title {
    font-size: 2rem;
  }

  .pricing-description {
    font-size: 1rem;
  }

  /* CTA Section Adjustments */
  .cta-left h2 {
    font-size: 2rem;
    text-align: center;
  }

  .cta-description {
    font-size: 1rem;
    text-align: center;
  }

  .cta-right {
    align-items: center;
  }

  .cta-form {
    max-width: 100%;
  }

  .cta-form input[type="email"],
  .cta-form button {
    height: 48px; /* Further reduced height */
    border-radius: 24px;
  }

  .cta-form input[type="email"] {
    padding: 0 1rem;
  }

  .cta-form button {
    padding: 0 1rem;
  }
}

/* Large Phones (480px to 767px) */
@media (max-width: 767px) and (min-width: 480px) {
  /* CTA Section Adjustments */
  .cta-container {
    flex-direction: column;
    align-items: center;
  }

  .cta-left {
    flex-basis: auto;
    margin-bottom: 2rem;
  }

  .cta-left h2 {
    font-size: 2rem;
    text-align: center;
  }

  .cta-right {
    flex-basis: auto;
    margin-left: 0;
    width: 100%;
    align-items: center;
  }

  .cta-description {
    font-size: 1rem;
    text-align: center;
  }

  .cta-form-container {
    align-items: center;
  }

  .cta-form {
    max-width: 500px;
  }

  .cta-form input[type="email"],
  .cta-form button {
    height: 48px;
    border-radius: 24px;
  }
}

/* Small Phones (up to 479px) */
@media (max-width: 479px) {
  /* Hero Section Adjustments */
  .hero-title {
    font-size: 1.75rem;
  }

  .hero-description {
    font-size: 0.875rem;
  }

  .hero-image {
    padding: 0.5rem;
  }

  /* Features Section Adjustments */
  .features-title {
    font-size: 1.5rem;
  }

  .features-section-description {
    font-size: 0.875rem;
  }

  /* Benefits Section Adjustments */
  .benefits-title {
    font-size: 1.5rem;
  }

  .benefits-description {
    font-size: 0.875rem;
  }

  /* Pricing Section Adjustments */
  .pricing-title {
    font-size: 1.5rem;
  }

  .pricing-description {
    font-size: 0.875rem;
  }

  /* CTA Section Adjustments */
  .cta-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    gap: 2rem; /* Add space between stacked elements */
  }

  .cta-left {
    width: 100%;
  }

  .cta-left h2 {
    font-size: 1.75rem;
    text-align: center;
  }

  .cta-right {
    width: 100%;
    margin-left: 0;
  }

  .cta-description {
    font-size: 0.875rem;
    text-align: center;
  }

  .cta-form-container {
    width: 100%;
    align-items: stretch;
  }

  .cta-form {
    width: 100%;
  }

  .cta-form input[type="email"],
  .cta-form button {
    height: 40px;
    border-radius: 20px;
  }

  .cta-form input[type="email"] {
    padding: 0 0.75rem;
  }

  .cta-form button {
    padding: 0 0.75rem;
  }
}
