/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.conditions-page {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: #4B5563;
  background: #F9FAFB;
  min-height: 100vh;
}

/* Navbar styles */
.conditions-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  z-index: 50;
}

.conditions-navbar-content {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
}

.conditions-logo {
  display: flex;
  align-items: center;
  height: 2rem;
  text-decoration: none;
}

.conditions-logo-icon {
  height: 2rem;
  width: auto;
}

/* Main content styles */
.conditions-content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 8rem 1.5rem 4rem;
}

.conditions-page-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #111827;
  margin-bottom: 2rem;
}

.conditions-last-updated {
  font-size: 0.875rem;
  color: #6B7280;
  margin-bottom: 2rem;
}

.conditions-section {
  margin-bottom: 2.5rem;
}

.conditions-section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1rem;
}

.conditions-section-content {
  margin-bottom: 1.5rem;
}

.conditions-section ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.conditions-section li {
  margin-bottom: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .conditions-content-wrapper {
    padding: 7rem 1rem 2rem;
  }

  .conditions-page-title {
    font-size: 2rem;
  }

  .conditions-section-title {
    font-size: 1.25rem;
  }
}
